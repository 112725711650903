.useful-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-text {
  font-size: clamp(3rem, 5vw, 5rem);
  margin-bottom: 0;
}

.useful-details {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: clamp(0.5rem, 1.5vw, 1rem);
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #55c4c0;
  color: white;
}

.bottom-section-left ul {
  display: flex;
  gap: 1rem;

}

@media screen and (max-width:768px) {

  .bottom-section{
    flex-direction: column;
  }

  .bottom-section-left ul{
   width: 100%;
   padding: 0;
  }

}

