.order-title {
  display: flex;
  justify-content: space-between;
}

.summary-title {
  margin: 1rem;
}

.order-container {
  height: 75vh;
  overflow: auto;
}

.order-container::-webkit-scrollbar {
  width: 0.5rem;
}

.order-container::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.main-order-container {
  display: flex;
  gap: 2rem;
  width: min(100%, 1400px);
  margin: 0 auto;
}


.order-summary {
  flex: 0.9;
}

@media screen and (max-width:768px) {
  .main-order-container {
    flex-direction: column;
  }
  .order-summary {
    margin-top: 0rem;
  }

}