.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.modal {
  background-color: white;
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
}

.modal-body {
  padding: 1rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.modal-body form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body form .form-group {
  width: 100%;
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.modal-cancel {
  border: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 900;
}

.modal-cancel button {
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: #e14949;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.login-or-register {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.cancel-modal-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.btn-submit-small {
  padding: 0.5rem 1rem !important;
}

.btn-cancel {
  background-color: #e14949 !important;
}

@media screen and (max-width:500px) {
  .modal-container{
    padding: 0
  }
}
