.is-order-summary {
  /* background-color: #f5f5f5; */
  background: #ffffff;
  box-shadow: 0px 0px 10px #e8e8e8;
  padding: 20px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  font-size: 1rem;
  padding: 1rem;
  /* height: 20px; */
  position: relative;
  box-sizing: border-box;
}
.select-dropdown {
  width: 100%;
  position: relative;
  /* background-color: #e6e6e6; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}
.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}
.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

select-dropdown::placeholder {
  font: 1.25rem/3 sans-serif;
}

.total-cost {
  display: flex;
  justify-content: space-between;
}

.final-cost {
  display: flex;
  justify-content: space-between;

}

.enter-promo * {
  display: flex;
  margin: 1rem 0;
}

.flat-button {
  font-weight: bold;
  font-size: 18px;
  border: none;
  color: #fff;
  background-color: #55c4c0;
  padding: 10px 15px;
  margin: 0rem 0.5rem 0.5rem 0rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.actual-product-price {
  /* font-size: 1rem; */
  font-weight: bold;
  color: #2756A1;
}

.promo-br {
  border-bottom: 1px solid #aaa;
}

.apply-promo {
  background-color: #8DA8E2;
  width: 100%;
}

.apply-promo-clicked {
  background-color: #FF9D4E;
  width: 100%;
}

.checkout {
  background-color: #FF9D4E;
  padding: 0.8rem 1.5rem;
  width: 100%;
}

.checkout:disabled {
  background-color: #525960;
  padding: 0.8rem 1.5rem;
  width: 100%;
}

.checkout-cancel {
  background-color: #ADADAD;
  padding: 0.8rem 1.5rem;
  width: 100%;
}

.increase-quantity {
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;
}

.increase-quantity button {
  border: none;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: larger;
}

.increase-quantity button:nth-of-type(1):hover {
  color: white;
  background-color: #f75e5e;
}

.increase-quantity button:nth-of-type(2):hover {
  color: white;
  background-color: #5ef75e;
}


.inputErr {
  border: 2px solid #ff2100;
  outline: none;
}

